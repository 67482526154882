import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './LatestPosts.module.css';

const LatestPosts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios.get('https://blog.enotoken.io/wp-json/wp/v2/posts?per_page=3&_embed')
      .then(response => {
        setPosts(response.data);
      })
      .catch(error => {
        console.error('Error fetching the posts:', error);
      });
  }, []);

  const createExcerpt = (htmlContent, maxLength) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const textContent = tempDiv.textContent || tempDiv.innerText || '';
    if (textContent.length > maxLength) {
      return textContent.slice(0, maxLength) + '...';
    }
    return textContent;
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <div className={styles.latestNews}>
      <div className={styles.latestContainer}>
        <div className={styles.headerContainer}>
          <h2>Latest News</h2>
          <a href="https://blog.enotoken.io/" className={styles.viewAllBlogsBtn}>View all blogs</a>
         </div>
         <div className={styles.postsContainer}>
          {posts.map(post => (
            <div key={post.id} className={styles.post}>
              <img src={post._embedded['wp:featuredmedia'][0].source_url} alt={post.title.rendered} className={styles.postImage} />
              <p className={styles.postDate}>{formatDate(post.date)}</p>
              <h2 className={styles.postTitle}>{post.title.rendered}</h2>
              <div className={styles.postExcerpt}>
                {createExcerpt(post.excerpt.rendered, 100)}
              </div>
              <div className={styles.btnContainer}>
                <a href={post.link} className={`${styles.heroBtn} ${styles.colorBtn}`} target="_blank" rel="noopener noreferrer">Read Now</a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LatestPosts;
